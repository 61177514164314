<template>
  <div class="ib-image-uploader bg-white">
    <!-- Default Image -->
    <div
      v-if="!image && !cropping && !croppedImage"
      class="default-image-wrapper position-relative"
      @click="$refs.imageUploader.click()"
    >
      <div
        class="default-image"
        :style="{backgroundImage: `url(${defaultImage})`}"
      />
      <div class="placeholder" @click="$emit('add')">
        <h1 class="text-white m-0">
          {{ $t('addPicture') }}
        </h1>
      </div>
      <div
        class="plus d-flex justify-content-center align-items-center position-absolute"
        :title=" $t('addPicture')"
        @click="$emit('add')"
      >
        <i class="el-icon-plus" />
      </div>
    </div>
    <!-- /Default Image -->

    <!-- Vue Cropper -->
    <vue-cropper
      v-show="cropping"
      ref="cropper"
      :guides="true"
      :view-mode="0"
      drag-mode="crop"
      :auto-crop-area="1"
      :min-container-width="451"
      :min-container-height="300"
      :background="true"
      :src="uploadedImage"
      output-type="png"
      :img-style="{ 'width': '451px', 'height': '193px' }"
    />
    <!-- /Vue Cropper -->

    <!-- Image -->
    <div
      v-if="(image || croppedImage) && !cropping"
      class="image position-relative"
      :style="image ? cardImageBySize(image, 'medium') : cardImage(croppedImage)"
    >
      <span class="placeholder" @click="onDelete">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M15 4h4a1 1 0 110 2h-1v13a3 3 0 01-3 3H5a3 3 0 01-3-3V6H1a1 1 0 110-2h4V3a3 3 0 013-3h4a3 3 0 013 3v1zm-2 0V3a1 1 0 00-1-1H8a1 1 0 00-1 1v1h6zm3 2H4v13a1 1 0 001 1h10a1 1 0 001-1V6zm-9 4a1 1 0 112 0v6a1 1 0 11-2 0v-6zm4 0a1 1 0 112 0v6a1 1 0 11-2 0v-6z"/></svg>
      </span>
    </div>
    <!-- /Image -->

    <!-- Input -->
    <input
      ref="imageUploader"
      class="ib-input-uploader position-absolute"
      type="file"
      accept="image/*"
      @change="setImage"
    >
    <!-- /Input -->
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'
import { cardImage } from '@/helpers/imageHelper'
import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  name: 'IbAvatarUploader',

  components: { VueCropper },

  props: {
    image: {
      type: Object,
      default: null
    },

    defaultImage: {
      type: String,
      default: require('@/assets/img/icons/story-mode/customer.svg')
    }
  },

  data () {
    return {
      cropping: false,
      uploadedImage: null,
      croppedImage: null
    }
  },

  watch: {
    '$breakpoint.xl' (value) {
      if (!value && this.cropping) {
        this.$emit('crop')
      }
    }
  },

  methods: {
    cardImage,

    setImage (e) {
      const file = e.target.files[0]
      const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

      if (!file.type.includes('image/')) {
        alert(`${this.$t('validationMessages.selectImageFile')}`)

        return
      }

      if (!isImageSizeLowerThen5M) {
        alert(`${this.$t('validationMessages.pictureSizeCanNotExceed', { size: '5MB' })}`)

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.uploadedImage = event.target.result
          if (this.$isTouchDevice && !this.$breakpoint.xl) {
            this.croppedImage = event.target.result
            this.$emit('change', event.target.result)
          } else {
            this.$refs.cropper.replace(event.target.result)
            this.cropping = true
            this.$emit('cropping')
          }
        }
        reader.readAsDataURL(file)
      } else {
        alert(`${this.$t('somethingWentWrong')}`)
      }
      e.target.value = ''
    },

    cropImage () {
      const imageBase64 = this.$refs.cropper.getCroppedCanvas().toDataURL()
      if (imageBase64.startsWith('data:image/')) {
        this.croppedImage = imageBase64
        this.cropping = false

        return {
          croppedImage: this.croppedImage,
          uploadedImage: this.uploadedImage
        }
      }

      return null
    },

    onDelete () {
      this.reset()
      this.$emit('delete')
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    reset () {
      this.cropping = false
      this.croppedImage = null
      this.uploadedImage = null
    }
  }
}
</script>

<style scoped lang="scss">
.ib-image-uploader {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 0.25s;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .ib-input-uploader {
    visibility: hidden;
  }

  .placeholder {
    cursor: pointer;
    background-color: rgba(41, 47, 77, 0.75);
    opacity: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    h1 {
      letter-spacing: 1px;
    }

    img, svg {
      path {
        fill: $color-white;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .default-image-wrapper {
    .default-image {
      background-position: center;
      background-repeat: no-repeat;
      min-height: 300px;
      background-size: 130px;
    }
  }

  &:hover {
    .plus {
      transform: translateY(-2px) translateX(-3px);
      transition: all 0.25s;
    }
  }

  .plus {
    width: 30px;
    height: 30px;
    transition: all 0.25s;
    color: $color-white;
    background-color: $color-primary;
    bottom: 16px;
    right: 16px;
    padding: 8px;
    border-radius: 50%;
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
  }
}
</style>
